import React from 'react';
import Vendor from './vendor';

const VendorList = props => {
  return (
    props.vendors &&
    props.vendors
      .filter(vendor => vendor.categories.some(category => category.id == props.productCategory.id))
      .map(vendor => <Vendor vendor={vendor} />)
  );
};

export default VendorList;
