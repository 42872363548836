import {Link} from 'gatsby'
import React from 'react'

import styles from './underlined-header.module.css'

function UnderlinedHeader ({text}) {
  return (
    <div className={styles.root}>
        <h2>{text}</h2>
    </div>
  )
}
UnderlinedHeader.defaultProps = {
    text: '',
  }
export default UnderlinedHeader
