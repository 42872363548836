import React from 'react';
import { graphql } from 'gatsby';
import { mapEdgesToNodes } from '../lib/helpers';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import VendorOverview from '../components/vendor-overview';
import SEO from '../components/seo';
import Layout from '../containers/layout';

export const query = graphql`
  query VendorsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      subtitle
      description
      keywords
    }
    vendors: allSanityVendor(filter: { active: { eq: true } }, sort: { fields: title }) {
      edges {
        node {
          title
          website
          categories {
            id
            title
          }
        }
      }
    }
    productCategories: allSanityProductCategory(sort: { fields: _createdAt }) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

const VendorsPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const vendorNodes = (data || {}).vendors ? mapEdgesToNodes(data.vendors) : [];

  const productCategoryNodes = (data || {}).productCategories ? mapEdgesToNodes(data.productCategories) : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title="Leverandører" description={site.description} keywords={site.keywords} />
      <Container>
        <h1>Leverandører</h1>
        <p>
          Vi er totalleverandør av klassisk og moderne interiør deriblant møbler, tapet, gardiner, lamper, tepper,
          sengetøy og pyntegjenstander. Under finner du komplett oversikt over våre leverandører.
        </p>
        <VendorOverview vendors={vendorNodes} productCategories={productCategoryNodes} />
      </Container>
    </Layout>
  );
};

export default VendorsPage;
