import React from 'react';
import VendorList from './vendor-list';
import UnderlinedHeader from './underlined-header';

const VendorOverview = props => {
  return (
    props.productCategories &&
    props.productCategories.map(category => (
      <div>
        <UnderlinedHeader text={category.title} />

        <VendorList productCategory={category} vendors={props.vendors} />
      </div>
    ))
  );
};

VendorOverview.defaultProps = {
  filter: ''
};

export default VendorOverview;
